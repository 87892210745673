@import "fonts";
/* You can add global styles to this file, and also import other style files */
@import "variables.scss";
@import "bootstrap/scss/bootstrap.scss";
@import '@ali-hm/angular-tree-component/css/angular-tree-component.css';

button,
input,
optgroup,
select,
textarea {
  font-family: $font-family-base !important;
}

.btn-primary, .btn-primary:hover {
  color: $white; // bootstrap chooses black for better contrast..
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
}

.carousel-control-prev, .carousel-control-next {
  align-items: flex-start;
  padding-top: 125px;
}
.carousel-indicators {
  visibility: hidden;
}

.btn-xl {
  @include button-size($input-btn-padding-y-lg, $input-btn-padding-x-lg, 1.5rem, $btn-border-radius-lg);
}

.required label::after {
  content: "*";
  color: $danger;
}
.form-control-feedback {
  color: $danger;
}
.has-danger {
  input {
    border-color: $danger !important;
  }
}

.cbfp-mouse-pointer {
  cursor: pointer;
}


.ngx-datatable {

  /* add '...' to truncated cells. Must be applied to innermost box element (that only contains 'inline' elements!
   (can be removed when https://github.com/swimlane/ngx-datatable/issues/612 is resolved) */
  @mixin overflow-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }

  width: 100%;

  .datatable-checkbox {
    cursor: pointer;
    display: inline;
    width: 50px; // fixed width, as icons (checked/unchecked) have different sizes
    input {
      display: none;
    }
  }
  .datatable-checkbox::before {
    font-family: FontAwesome;
    content: "\f096"; // unchecked
    display: inline-block;
    width: 23px;
  }

  .datatable-body-row.active .datatable-checkbox::before, &.all-selected datatable-header .datatable-checkbox::before {
    content: "\f046";
  }

  .datatable-body-cell, .datatable-header-cell {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }

  .datatable-header {
    .datatable-header-inner {
      .datatable-header-cell {
        @extend strong;

        div {
          @include overflow-ellipsis;
        }
      }
    }

    .datatable-checkbox {
      position: absolute; // fixing table header distortion
    }

    // central handle bar:
    .resize-handle:after {
      content: "";
      position: absolute;
      //z-index: -1;
      top: 0;
      bottom: 0;
      left: 50%;
      border-left: 1px solid black;
      border-right: 1px solid black;
      transform: translate(-50%);
    }

    // show all resize-handlers (default: only for current column):
    &:hover {
      .resizeable {
        .resize-handle {
          visibility: visible;
        }
      }
    }
    .resize-handle {
      width: 20px !important;
      /* the parent (column title) has overflow:hidden, hence we cannot extend further to right.
         This leads to incorrect aligned resize-handler. */
      //right: -10px !important;
      background: white;
    }

  }
  .datatable-body-row {
    .datatable-body-cell {
      .datatable-body-cell-label {
        @include overflow-ellipsis;
      }

      // assure details icon is shown:
      &.cbfp-overflow-visible {
        .datatable-body-cell-label {
          overflow: visible;
        }
      }
    }
  }

  .pager {
    .disabled .fa {
      color: grey;
    }
    .active {
      @extend .badge;
      @extend .text-bg-primary;
      a {
        color: white !important;
      }
    }
    li {
      @extend .p-2;
      @extend .m-1;
    }
  }

  datatable-progress {
    .progress-linear {
      @extend .progress;
      .container {
        @extend .progress-bar;
        @extend .progress-bar-striped;
        @extend .progress-bar-animated;
        width: 100%;
      }
    }
  }

  // row details
  dd, li {
    white-space: normal !important;
  }
}

/* landing page:
   (cannot be included in about.component.scss, as using/including bootstrap.scss there increases bundle size!) */
app-about {
  .jumbo-even, .jumbo-odd {
    font-size: $lead-font-size;
    font-weight: $lead-font-weight;
    h3 {
      @extend .display-4;
    }
    .row {
      div:first-child {
        @extend .text-center;
        @extend .align-self-center;
      }
      div:nth-child(2) {
        @extend .pe-md-5;
      }
    }
  }

  .inverted {
    background-color: $primary !important;
    color: white;
  }

  .jumbo-even {
    background-color: white;
    .row {
      div:first-child {
        text-align: right;
      }
    }
  }

  .jumbo-odd {
    background-color: $primary;
    color: white;
    a {
      color: grey;
    }
    .row {
      /* pull image right */
      @include media-breakpoint-up(md) {
        div:first-child {
          // cannot "@extend .flex-last" from within "@include":
          -webkit-box-ordinal-group: 2;
          -webkit-order: 1;
          -ms-flex-order: 1;
          order: 1;
        }

      }
      div:nth-child(2) {
        @extend .ps-md-5;
      }
    }
  }

  .icon-image {
    font-size: 12rem;
    @include media-breakpoint-up(md) {
      margin-top: 4rem;
    }
  }

  h1 {
    font-size: 4rem;
  }
  .cbfp-subheader {
    font-size: 3rem;
    font-weight: $thin;
    @extend .text-muted;
    @extend .float-end;
  }
  .cbfp-bg-image {
    background: url("assets/background_landing.svg") no-repeat fixed center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: $primary;
  }

}

ngb-timepicker .form-control {
  width: 100% !important; // workaround for bug
}


// fixing bug: clicking elements after scrolling were not selected:
.dropdown-menu.show {
  max-height: 300px;
  overflow-x: auto;
}
