// see: ui/node_modules/bootstrap/scss/_variables.scss

$gray-100: #f8f9fa !default;
$gray-600: #868e96 !default;
$gray-800: #343a40 !default;
$red: #dc3545;
$yellow: #ffc107;
$green: #28a745;

// bootstrap theme colors:
$primary: #08c8cb;
$secondary: $gray-600;
$success: $green;
$info: #5091bd;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

$navbar-padding-x: 1rem;
$min-contrast-ratio: 3;

// old website: #66B3FF;
$font-family-sans-serif: "Raleway", sans-serif;
$font-family-serif: Georgia, "Times New Roman", Times, serif;
$font-family-base: $font-family-sans-serif;

$thin: 100;
$normal: 400;
$bold: 700;

$font-weight-normal: $normal;
$font-weight-bold: $bold;
$font-weight-base: $normal;
$headings-font-weight: $normal;
$lead-font-weight: $normal;
$display1-weight: $thin;
$display2-weight: $thin;
$display3-weight: $thin;
$display4-weight: $thin;

// elements with border: content, backup tree, ..
//$card-border-width: 0;
